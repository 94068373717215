<template>
  <b-form-input
    class="input-style text-right"
    autocomplete="off"
    v-model="_value"
    v-bind:class="_class"
    :size="size"
    :formatter="formatter"
    :placeholder="placeholder"
    :disabled="disabled"
  ></b-form-input>
</template>
<script>
import { unMaskMoney, convertPrice } from '@/utils/common';
export default {
  props: {
    value: {
      value: '',
      required: true,
    },
    size: {
      value: null,
      default: 'sm',
    },
    placeholder: {
      value: null,
      default: 'Vui lòng nhập',
    },
    inputClass: {
      value: null,
      default: '',
    },
    disabled: {
      value: null,
      default: false,
    },
  },
  model: {
    prop: 'value',
    event: 'valueChange',
  },
  computed: {
    _value: {
      get() {
        return this.value ? this.formatter(this.value) : null;
      },
      set(value) {
        this.$emit('valueChange', Number(unMaskMoney(value)));
      },
    },
    _class: {
      get() {
        return this.inputClass;
      },
    },
  },
  methods: {
    formatter(value) {
      return convertPrice(Number(unMaskMoney(value)));
    },
  },
};
</script>
