<template>
  <div v-if="item.child">
    <b-form-group :disabled="disabled">
      <label>Phí chuyển đổi:</label>
      <b-form-select
        v-model="item.child.paymentOptionType"
        class="select-style"
        size="sm"
        value-field="id"
        text-field="name"
        :options="[
          {
            id: null,
            name: 'Chọn hình thức thanh toán',
          },
          {
            id: 'TM',
            name: 'Tiền mặt',
            disabled: !this.filteredCashAccOptions.length,
          },
          {
            id: 'CK',
            name: 'Chuyển khoản',
          },
          {
            id: 'QT',
            name: 'Quẹt thẻ',
          },
          {
            id: 'TG',
            name: 'Trả góp',
          },
        ]"
        @change="
          onChangeCheckBox({
            value: item.child.paymentOptionType,
          })
        "
      >
      </b-form-select>
    </b-form-group>
    <b-form-group :disabled="disabled">
      <b-form-input
        v-if="item.child.paymentAmount===0"
        class="input-style text-right"
        v-model="item.child.paymentAmount"
        :disabled="true"
        :placeholder="conversionFeePlaceholder"
      ></b-form-input>
      <BInputNumber
        v-else
        v-model="item.child.paymentAmount"
        :disabled="true"
        :placeholder="conversionFeePlaceholder"
      />
    </b-form-group>
    <b-form-group :disabled="disabled">
      <label>Tài khoản thu phí chuyển đổi:</label>
      <Autosuggest
        placeholder="tài khoản chuyển khoản"
        :model="item.child.paymentName"
        :suggestions="filteredOptions"
        @change="onInputChange($event, item.child.paymentOptionType)"
        @select="onSelectedMul($event)"
      />
    </b-form-group>
    <b-form-group
      :disabled="disabled"
      class="my-2"
    >
      <b-form-input
        class="input-style"
        size="sm"
        autocomplete="off"
        v-model="item.child.paymentCode"
        placeholder="Mã tham chiếu"
      ></b-form-input>
    </b-form-group>
  </div>
</template>

<script>
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import BInputNumber from '@/view/base/bootstrap/BInputNumber.vue';
import { mapGetters } from 'vuex';
import { removeAccents } from '@/utils/common';

export default {
  props: {
    item: {
      type: Object,
    },
    disabled: {
      type: Boolean,
    },
    filteredCashAccOptions: {
      type: Array,
    },
    filteredTransferAccOptions: {
      type: Array,
    },
    filteredCreditAccOptions: {
      type: Array,
    },
    filteredOptionsInstallment: {
      type: Array,
    },
    cashAccOptions: {
      type: Array,
    },
    transferOptions: {
      type: Array,
    },
  },
  data() {
    return {
      filteredOptions: [],
      optionsPaymentType: [],
      defaultValue: {
        id: this.$uuid.v4(),
        paymentRefId: null,
        paymentRefCode: null,
        paymentName: null,
        paymentAmount: null,
        paymentCode: null,
        transactionId: null,
        paymentOptionType: null,
      },
      conversionFeePlaceholder: 'Phí chuyển đổi'
    };
  },
  components: {
    Autosuggest,
    BInputNumber,
  },
  created() {
    if (!this.item.child) {
      this.item.child = this.defaultValue;
    }
  },
  watch: {
    'item.paymentAmount': {
      handler: function (newValue) {
        if (this.item.child) {
          this.item.child.paymentAmount =
            (newValue * this.getInstallmentProgram.conversionFeePercent) / 100;
        }
      },
    },
  },
  computed: {
    ...mapGetters(['getInstallmentProgram', 'getInstallmentInterestZero']),
  },
  mounted() {},
  methods: {
    onChangeCheckBox(option) {
      if (option.value === 'TM') {
        this.filteredOptions = this.filteredCashAccOptions;
      } else if (option.value === 'CK') {
        this.filteredOptions = this.filteredTransferAccOptions;
      } else if (option.value === 'QT') {
        this.filteredOptions = this.filteredCreditAccOptions;
      } else if (option.value === 'TG') {
        this.filteredOptions = this.filteredOptionsInstallment;
      }
    },
    isTextNul(text) {
      if (!text || !text.length) {
        return true;
      }
      return false;
    },
    onSelectedMul(option) {
      if (!option) {
        return;
      }
      this.item.child.paymentRefId = option.item.id;
      this.item.child.paymentRefCode = option.item.code;
      this.item.child.paymentName = option.item.name;
    },
    onInputChange(text, type) {
      switch (type) {
        case 'TM': {
          this.filteredOptions = this.onFilterOptions(
            this.cashAccOptions,
            text,
          );
          break;
        }
        case 'CK': {
          this.filteredOptions = this.onFilterOptions(
            this.transferOptions,
            text,
          );
          break;
        }
      }
      if (this.isTextNul(text)) {
        this.item.child.paymentRefId = null;
        this.item.child.paymentRefCode = null;
        this.item.child.paymentName = null;
        return;
      }
    },
    onFilterOptions(options, text) {
      const filteredData = options[0].data
        .filter((item) => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      return filteredData;
    },
  },
};
</script>