import ApiService from '@/core/services/api.service';

const commonMixin = {
  created: function() {},
  methods: {
    fetchStores: async function() {
      return ApiService.get('/stores/getStores').then(response => {
        return response.data;
      });
    },
    fetchOrderSources() {
      const params = {
        status: 1
      };
      return ApiService.query(`orderSources/getAll`, {
        params: params
      }).then(response => {
        return response.data;
      });
    },
  }
};
export default commonMixin;
