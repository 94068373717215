<template>
  <div class="mb-2">
    <div>
      <b-form-group class="mb-0 mt-2">
        <label
          >Cấu hình Shinhan:
          <b
            class="text-primary ml-2"
            v-if="getInstallmentShinhan.loanApplicationId"
          >
            #{{ getInstallmentShinhan.loanApplicationId }}</b
          ></label
        >
        <b-form-group>
          <b-form-select
            class="select-style"
            :options="LOAN_STATUS"
            size="sm"
            value-field="value"
            text-field="text"
            v-model="getInstallmentShinhan.installApplicationStatus"
            :disabled="getInstallmentShinhan.loanApplicationId ? false : true"
          >
            <template v-slot:first>
              <b-form-select-option :value="-1" disabled
                >Chọn trạng thái vay</b-form-select-option
              >
            </template>
          </b-form-select>
        </b-form-group>
      </b-form-group>
      <div class="d-flex d-inline">
        <label class="textTitlePayment"> Thông tin khoản vay:</label>
        <div>
          <i
            class="text-primary cursor-pointer pl-2"
            :class="showHtmlLoanCreate ? 'fa fa-plus-circle' : 'far fa-eye'"
            style="padding-top: 2px"
            @click="showLoanData(showHtmlLoanCreate ? 'NEW' : 'DETAIL')"
          >
          </i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { LOAN_STATUS } from '@/utils/constants';

const PAYMENT_TYPE = {
  INSTALLMENT: 'INSTALLMENT',
};
export default {
  props: {
    paymentType: {
      type: String,
      required: true,
      default: null,
      validator: (value) => {
        return Object.values(PAYMENT_TYPE).includes(value);
      },
    },
    isInterestShinhan: {
      type: Boolean,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: null,
    },
    validate: {
      type: Boolean,
      default: true,
    },
    installmentAmount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      LOAN_STATUS,
    };
  },
  components: {},
  created() {},
  mounted() {},
  beforeDestroy() {},
  watch: {},
  computed: {
    ...mapGetters(['getInstallmentShinhan']),
    showHtmlLoanCreate() {
      return (
        this.getInstallmentShinhan.installApplicationStatus === -1 ||
        !this.getInstallmentShinhan.installApplicationStatus
      );
    },
  },
  methods: {
    showLoanData(mode) {
      this.$emit('show-loan-data', mode);
    },
  },
};
</script>
