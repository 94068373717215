import ApiService from '@/core/services/api.service';
import { makeToastFaile } from '@/utils/common';

export default {
  data() {
    return {
      requiredStockSlips: null,
      isLoadingGetRequiredStockSlipListByIds: false,
      errorGetRequiredStockSlipList: null,
    };
  },
  methods: {
    async getRequiredStockSlipListByIds(ids) {
      if (this.isLoadingGetRequiredStockSlipListByIds) return;
      this.isLoadingGetRequiredStockSlipListByIds = true;

      try {
        const responses = await Promise.all(
          ids.map((id) => ApiService.get(`stocks/transfer-stock/${id}`)),
        );
        const datas = responses.map((res) => res.data.data);
        this.requiredStockSlips = datas;
      } catch (error) {
        if (error.response) {
          makeToastFaile(
            error.response.data
              ? error.response.data.message
              : 'Có vấn đề xảy ra hãy thử lại',
          );
        } else {
          makeToastFaile(error.message || 'Có vấn đề xảy ra hãy thử lại');
        }
      } finally {
        this.isLoadingGetRequiredStockSlipListByIds = false;
      }
    },
  },
};
