<template>
  <b-modal
    :ref="refModal"
    size="md"
    hide-footer
    :id="refModal"
  >
    <template #modal-title>
      <h5
        v-html="titleExcel"
        class="mb-0"
      ></h5>
    </template>

    <b-row>
      <b-col
        cols="6"
        class="mb-4"
      >
        <b-form-select
          class="select-style"
          v-model="paymentType"
          :options="enumPayments"
          size="sm"
          value-field="id"
          text-field="name"
        >
          <template v-slot:first>
            <b-form-select-option :value="null">Chọn hình thức thanh toán</b-form-select-option>
          </template>
        </b-form-select>
      </b-col>
    </b-row>

    <b-overlay
      :show="isProcessing"
      rounded
      opacity="0.6"
      class="d-inline-block"
    >
      <template #overlay>
        <div class="d-flex align-items-center">
          <b-spinner
            variant="light"
            small
            type="grow"
          ></b-spinner>
        </div>
      </template>
      <b-button
        class="mr-3"
        variant="primary"
        size="sm"
        v-if="paymentType"
        @click.once="onAddPayments"
      >
        <strong>Thêm thanh toán</strong>
      </b-button>
    </b-overlay>
    <b-button
      style="width: 80px"
      variant="secondary"
      size="sm"
      @click="$bvModal.hide(refModal)"
      v-if="paymentType"
    >
      <strong>Hủy</strong>
    </b-button>
  </b-modal>
</template>

<script>
import { validationMixin } from 'vuelidate';
import ApiService from '@/core/services/api.service';
import { EventBus } from '@/core/services/event-bus';

export default {
  props: {
    excludes: {
      type: Array,
      default: () => [],
    },
  },
  mixins: [validationMixin],
  data() {
    return {
      titleExcel: 'Thêm hình thức thanh toán',
      isProcessing: false,
      refModal: 'modal-addition-payment',
      enumPayments: [],
      paymentType: null,
    };
  },
  components: {},
  computed: {},
  created() {
    this.fetchEnumPayment();
  },
  watch: {
    $props: {
      handler() {},
      deep: true,
      immediate: true,
    },
  },
  validations: {},
  methods: {
    fetchEnumPayment() {
      const params = { excludes: this.excludes };
      ApiService.query('payments/enum', { params }).then((response) => {
        const { data } = response.data;
        this.enumPayments = data;
      });
    },
    onAddPayments() {
      if (this.paymentType) {
        EventBus.$emit(`add-payments-${this.paymentType}`, this.paymentType);
        this.$bvModal.hide(this.refModal);
      }
    },
  },
};
</script>
