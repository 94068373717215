import jQuery from 'jquery';

export default {
  data() {
    return {
      hoverTable: {
        dataId: null,
      },
    };
  },
  methods: {
    hoverBill(evt) {
      const items = jQuery(evt.currentTarget);
      const dataId = jQuery(items[0]).attr('data');
      const rows = jQuery(`[data=${dataId}]`);
      rows.addClass('tr-rowspan-hover');
    },
    outBill(evt) {
      const items = jQuery(evt.currentTarget);
      const dataId = jQuery(items[0]).attr('data');
      const rows = jQuery(`[data=${dataId}]`);
      rows.removeClass('tr-rowspan-hover');
    },
    hoverRow(evt) {
      const items = jQuery(evt.currentTarget);
      const dataId = jQuery(items[0]).attr('data');
      if (this.hoverTable.dataId !== dataId) {
        const rows = jQuery(`[data=${dataId}]`);
        rows.addClass('tr-rowspan-hover');
        this.hoverTable.dataId = dataId;
      }
    },
    outRow(evt) {
      const items = jQuery(evt.currentTarget);
      const dataId = jQuery(items[0]).attr('data');
      const rows = jQuery(`[data=${dataId}]`);
      rows.removeClass('tr-rowspan-hover');
      this.hoverTable.dataId = null;
    },
  },
};
