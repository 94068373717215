<template>
  <div>
    <b-modal
      ref="loan-data-modal"
      hide-footer
      :titleHtml="titelModal"
      size="xl"
      scrollable
    >
      <div>
        <b-row>
          <b-col md="3">
            <p class="text-dark font-size-h5 font-weight-bold">
              Thông tin khoản vay
            </p>
            <b-form-group class="required-control">
              <label>Trả trước:</label>
              <b-form-input
                class="text-right"
                size="sm"
                v-model="mainModel.prepaidAmount"
                v-mask="mask"
                aria-describedby="input-downpayment-live-feedback"
                @change="checkValidateDownpayment()"
                :disabled="mainModel.ddvStatus !== 1"
              ></b-form-input>
              <b-form-invalid-feedback
                id="input-downpayment-live-feedback"
                :state="validateDownpayment"
                >{{ messageErrorDownpayment }}</b-form-invalid-feedback
              >
            </b-form-group>
            <b-form-group class="required-control">
              <label>Số tiền vay:</label>
              <b-form-input
                class="text-right"
                size="sm"
                v-model="mainModel.loanAmount"
                v-mask="mask"
                aria-describedby="input-loan-amount-live-feedback"
                @change="checkValidateLoanAmount()"
                :disabled="mainModel.ddvStatus !== 1"
              ></b-form-input>
              <b-form-invalid-feedback
                id="input-downpayment-live-feedback"
                :state="validateLoanAmount"
                >{{ messageErrorLoanAmount }}</b-form-invalid-feedback
              >
            </b-form-group>
            <b-form-group class="required-control">
              <label>Mã chương trình vay:</label>
              <b-form-select
                v-model="mainModel.promotionCode"
                :options="listCampaign"
                size="sm"
                class="select-style"
                value-field="campaignCode"
                text-field="campaignCode"
                @change="onSelectedCampaign"
                :disabled="mainModel.ddvStatus !== 1"
              >
                <template v-slot:first>
                  <b-form-select-option :value="''" disabled
                    >Chọn chương trình vay</b-form-select-option
                  >
                </template></b-form-select
              >
            </b-form-group>
            <b-form-group class="required-control">
              <b-row>
                <b-col class="pr-0" cols="4">
                  <label>Lãi suất:</label>
                  <br />
                  <span>{{ mainModel.interestRateValue }}%</span>
                </b-col>
                <b-col class="pl-0" cols="8">
                  <label>Thời hạn vay:</label>
                  <b-form-select
                    v-model="mainModel.tenorCode"
                    :options="listTenor"
                    size="sm"
                    class="select-style"
                    value-field="id"
                    text-field="name"
                    :disabled="mainModel.ddvStatus !== 1"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="''" disabled
                        >Chọn thời gian vay</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group>
              <label>Số tiền trả hàng tháng:</label>
              <br />
              <span>{{ convertPrice(emiAmount) }}</span>
            </b-form-group>
            <b-form-group v-if="mode === 'DETAIL'">
              <label>Trạng thái vay:</label>
              <b-form-group>
                <b-form-select
                  :options="LOAN_STATUS"
                  size="sm"
                  value-field="value"
                  text-field="text"
                  v-model="mainModel.ddvStatus"
                  @change="onChangeStatus()"
                ></b-form-select>
              </b-form-group>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <p class="text-dark font-size-h5 font-weight-bold">
              Thông tin khách hàng
            </p>
            <b-form-group>
              <label>Điện thoại: <span class="text-danger">*</span></label>
              <vue-autosuggest
                :suggestions="optionCustomers"
                :get-suggestion-value="(customer) => customer.item.phoneNo"
                :limit="10"
                :input-props="{
                  class: 'autosuggest__input',
                  placeholder: 'SĐT Khách hàng',
                  disabled: false,
                }"
                :should-render-suggestions="
                  (size, loading) => size >= 0 && !loading
                "
                @input="onInputed($event, 'Customer')"
                v-model="mainModel.phone"
                @selected="onSelected($event, 'Customer')"
              >
                <div slot-scope="{ suggestion }">
                  <span class="mr-1">{{ suggestion.item.phoneNo }}</span>
                  <span
                    class="text-primary"
                    v-if="
                      suggestion.item.fullName &&
                      suggestion.item.fullName.trim().length > 0
                    "
                  >
                    - {{ suggestion.item.fullName }}
                  </span>
                </div>
              </vue-autosuggest>
            </b-form-group>
            <b-form-group class="required-control">
              <label>Họ tên:</label>
              <b-row>
                <b-col class="pr-0" cols="4">
                  <b-form-select
                    v-model="mainModel.gender"
                    :options="[
                      {
                        id: 1,
                        name: 'Anh',
                      },
                      {
                        id: 2,
                        name: 'Chị',
                      },
                    ]"
                    size="sm"
                    class="select-style"
                    value-field="id"
                    text-field="name"
                    :disabled="mainModel.ddvStatus !== 1"
                  ></b-form-select>
                </b-col>
                <b-col class="pl-0" cols="8">
                  <b-form-input
                    class="input-style"
                    size="sm"
                    v-model="mainModel.fullName"
                    placeholder="Họ tên khách hàng"
                    :disabled="mainModel.ddvStatus !== 1"
                  ></b-form-input>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group class="required-control">
              <label>Email:</label>
              <b-form-input
                class="input-style"
                size="sm"
                v-model="mainModel.email"
                placeholder="Email khách hàng"
                :disabled="mainModel.ddvStatus !== 1"
              ></b-form-input>
            </b-form-group>
            <b-form-group class="required-control">
              <label>Ngày sinh:</label>
              <date-picker
                placeholder="Chọn ngày sinh"
                class="form-control form-control-sm"
                :config="dpConfigs.date"
                v-model="mainModel.dateOfBirth"
                :disabled="mainModel.ddvStatus !== 1"
              ></date-picker>
            </b-form-group>
            <b-form-group class="required-control">
              <label>Số CMND/ CCCD :</label>
              <b-row>
                <b-col class="pr-0" cols="6">
                  <b-form-select
                    v-model="mainModel.idCardType"
                    :options="[
                      {
                        id: 0,
                        name: 'CMND',
                      },
                      {
                        id: 1,
                        name: 'CCCD',
                      },
                    ]"
                    size="sm"
                    class="select-style"
                    value-field="id"
                    text-field="name"
                    :disabled="mainModel.ddvStatus !== 1"
                    @change="checkValidIdCard"
                  ></b-form-select>
                </b-col>
                <b-col class="pl-0" cols="6">
                  <b-form-input
                    class="input-style"
                    size="sm"
                    v-model="mainModel.idCard"
                    placeholder="Số CMND/ CCCD/ Hộ chiếu:"
                    :disabled="mainModel.ddvStatus !== 1"
                    aria-describedby="input-idCard-live-feedback"
                    @change="checkValidIdCard"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    id="input-idCard-live-feedback"
                    :state="validateIdCard"
                    >{{ messageErrorIdCard }}</b-form-invalid-feedback
                  >
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <p class="text-dark font-size-h5 font-weight-bold">
              Địa chỉ thường trú
            </p>
            <b-form-group class="required-control">
              <label>Thành phố:</label>
              <b-form-select
                class="select-style"
                v-model="mainModel.permanentCityCode"
                :options="listCity"
                size="sm"
                value-field="id"
                text-field="name"
                @change="getDistricts('permanent')"
                :disabled="mainModel.ddvStatus !== 1"
              >
                <template v-slot:first>
                  <b-form-select-option :value="''" disabled
                    >Chọn thành phố</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form-group>

            <b-form-group class="required-control">
              <label>Quận huyện:</label>
              <b-form-select
                class="select-style"
                v-model="mainModel.permanentDistrictCode"
                :options="listDistrict"
                size="sm"
                value-field="id"
                text-field="name"
                @change="getWards('permanent')"
                :disabled="mainModel.ddvStatus !== 1"
              >
                <template v-slot:first>
                  <b-form-select-option :value="''" disabled
                    >Chọn quận</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form-group>

            <b-form-group class="required-control">
              <label>Phường xã:</label>
              <b-form-select
                class="select-style"
                v-model="mainModel.permanentWardCode"
                :options="listWard"
                size="sm"
                value-field="id"
                text-field="name"
                :disabled="mainModel.ddvStatus !== 1"
              >
                <template v-slot:first>
                  <b-form-select-option :value="''" disabled
                    >Chọn phường</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form-group>
            <b-form-group class="required-control">
              <label>Tên đường:</label>
              <b-form-input
                class="input-style"
                size="sm"
                v-model="mainModel.permanentStreet"
                placeholder="Nhập tên đường"
                :disabled="mainModel.ddvStatus !== 1"
              ></b-form-input>
            </b-form-group>
            <b-form-group class="required-control">
              <label>Số nhà:</label>
              <b-form-input
                class="input-style"
                size="sm"
                v-model="mainModel.permanentHouseNo"
                placeholder="Nhập số nhà"
                :disabled="mainModel.ddvStatus !== 1"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <p class="text-dark font-size-h5 font-weight-bold">
              Địa chỉ hiện tại
            </p>
            <b-form-group class="required-control">
              <label>Thành phố:</label>
              <b-form-select
                class="select-style"
                v-model="mainModel.presentCityCode"
                :options="listPresentCity"
                size="sm"
                value-field="id"
                text-field="name"
                @change="getDistricts('present')"
                :disabled="mainModel.ddvStatus !== 1"
              >
                <template v-slot:first>
                  <b-form-select-option :value="''" disabled
                    >Chọn thành phố</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form-group>

            <b-form-group class="required-control">
              <label>Quận huyện:</label>
              <b-form-select
                class="select-style"
                v-model="mainModel.presentDistrictCode"
                :options="listPresentDistrict"
                size="sm"
                value-field="id"
                text-field="name"
                @change="getWards('present')"
                :disabled="mainModel.ddvStatus !== 1"
              >
                <template v-slot:first>
                  <b-form-select-option :value="''" disabled
                    >Chọn quận</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form-group>

            <b-form-group class="required-control">
              <label>Phường xã:</label>
              <b-form-select
                class="select-style"
                v-model="mainModel.presentWardCode"
                :options="listPresentWard"
                size="sm"
                value-field="id"
                text-field="name"
                :disabled="mainModel.ddvStatus !== 1"
              >
                <template v-slot:first>
                  <b-form-select-option :value="''" disabled
                    >Chọn phường</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form-group>
            <b-form-group class="required-control">
              <label>Tên đường:</label>
              <b-form-input
                class="input-style"
                size="sm"
                v-model="mainModel.presentStreet"
                placeholder="Nhập tên đường"
                :disabled="mainModel.ddvStatus !== 1"
              ></b-form-input>
            </b-form-group>
            <b-form-group class="required-control">
              <label>Số nhà:</label>
              <b-form-input
                class="input-style"
                size="sm"
                v-model="mainModel.presentHouseNo"
                placeholder="Nhập số nhà"
                :disabled="mainModel.ddvStatus !== 1"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <div>
        <b-row v-if="mode === 'DETAIL'">
          <b-col md="3">
            <b-button
              style="width: 70px"
              variant="primary"
              size="sm"
              class="mr-3"
              @click="onSave()"
              :disabled="mainModel.ddvStatus !== 1"
              >Lưu</b-button
            >
            <b-button
              style="width: 70px"
              variant="secondary"
              size="sm"
              class="mr-3"
              @click="hideModal()"
              >Hủy</b-button
            >
            <b-button
              style="fontweight: 600; width: max-content"
              variant="warning"
              size="sm"
              @click="onSubmit()"
              :disabled="mainModel.ddvStatus !== 1"
              >Gửi hồ sơ</b-button
            >
          </b-col>
          <b-col md="3">
            <b-form-group :disabled="!isAbleToUpload">
              <b-form-file
                accept=".jpg, .png, .gif, .jpeg"
                placeholder="Hình ảnh hóa đơn"
                @change="selectFile($event, 'file-input-invoice')"
                v-model="fileInvoice"
                ref="file-input-invoice"
                browse-text="Chọn tệp"
              ></b-form-file>
              <label
                class="description text-warning"
                style="font-size: 10px"
                v-if="fileInvoiceUrl"
              >
                File đã được upload!</label
              >
              <label class="description" style="font-size: 10px" v-else>
                Chỉ nhận file jpg, jpeg, gif, png và nhỏ hơn 20MB</label
              >
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group :disabled="!isAbleToUpload">
              <b-form-file
                accept=".jpg, .png, .gif, .jpeg"
                placeholder="Giấy xác nhận cho vay"
                @change="selectFile($event, 'file-input-loan-confirm')"
                v-model="fileLoanConfirm"
                ref="file-input-loan-confirm"
                browse-text="Chọn tệp"
              ></b-form-file>
              <label
                class="description text-warning"
                style="font-size: 10px"
                v-if="fileLoanConfirmUrl"
              >
                File đã được upload!</label
              >
              <label class="description" style="font-size: 10px" v-else>
                Chỉ nhận file jpg, jpeg, gif, png và nhỏ hơn 20MB</label
              >
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group :disabled="!isAbleToUpload">
              <b-form-file
                accept=".jpg, .png, .gif, .jpeg"
                placeholder="Phiếu thu khoản trả trước"
                @change="selectFile($event, 'file-input-prepaid-receipts')"
                v-model="filePrepaidReceipts"
                ref="file-input-prepaid-receipts"
                browse-text="Chọn tệp"
              ></b-form-file>
              <label
                class="description text-warning"
                style="font-size: 10px"
                v-if="filePrepaidReceiptsUrl"
              >
                File đã được upload!</label
              >
              <label class="description" style="font-size: 10px" v-else>
                Chỉ nhận file jpg, jpeg, gif, png và nhỏ hơn 20MB</label
              >
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-else>
          <b-col md="3">
            <b-button
              style="width: 70px"
              variant="primary"
              size="sm"
              class="mr-3"
              @click="onSave()"
              :disabled="mainModel.ddvStatus !== 1"
              >Lưu</b-button
            >
            <b-button
              style="width: 70px"
              variant="secondary"
              size="sm"
              class="mr-3"
              @click="hideModal()"
              >Hủy</b-button
            >
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  TIME_TRIGGER,
  LOAN_STATUS,
  LOAN_TENOR,
  SHINHAN_ID,
} from '../../../utils/constants';
import { cloneDeep, debounce, valuesIn, includes } from 'lodash';
import ApiService from '@/core/services/api.service';
// Import datePicker component
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';

jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});
import {
  currencyMask,
  unMaskPrice,
  convertPrice,
  makeToastFaile,
  makeToastSuccess,
} from '../../../utils/common';
import moment from 'moment';
import Swal from 'sweetalert2';
import { LOAN_APPLICATION_STATUS } from '../../../utils/enum';

export default {
  props: ['loanApplicationId', 'totalAmount', 'mode', 'loanApplicationData'],
  data() {
    return {
      titelModal: '',
      mainModel: {
        orderId: null,
        phone: '',
        fullName: '',
        presentHouseNo: '',
        presentStreet: '',
        permanentHouseNo: '',
        permanentStreet: '',
        permanentCityCode: '',
        presentCityCode: '',
        permanentDistrictCode: '',
        permanentWardCode: '',
        presentDistrictCode: '',
        presentWardCode: '',
        gender: 1,
        email: '',
        dateOfBirth: '',
        promotionCode: '',
        prepaidAmount: 0,
        loanAmount: 0,
        tenorCode: '',
        emi: 0,
        interestRateValue: 0,
        idCard: '',
        idCardType: 1,
        ddvStatus: 1,
        partner: '',
      },
      filteredCustomers: [],
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      listCity: [],
      listDistrict: [],
      listWard: [],
      mask: currencyMask,
      LOAN_STATUS: LOAN_STATUS,
      listTenor: LOAN_TENOR,
      listPresentCity: [],
      listPresentDistrict: [],
      listPresentWard: [],
      isNew: true,
      fileInvoice: null,
      fileLoanConfirm: null,
      filePrepaidReceipts: null,
      listCampaign: [],
      messageErrorDownpayment: '',
      validateDownpayment: true,
      messageErrorLoanAmount: '',
      validateLoanAmount: true,
      originalStatus: null,
      fileInvoiceUrl: '',
      fileLoanConfirmUrl: '',
      filePrepaidReceiptsUrl: '',
      messageErrorIdCard: '',
      validateIdCard: true,
      trackingId: '',
    };
  },
  computed: {
    optionCustomers() {
      return [{ data: cloneDeep(this.filteredCustomers) }];
    },
    emiAmount() {
      const selectedTenor = this.listTenor.find(
        (tenor) => tenor.id === this.mainModel.tenorCode
      );
      if (selectedTenor) {
        const tenorValue = selectedTenor.value ? selectedTenor.value : 0;
        const interestAmount =
          (unMaskPrice(this.mainModel.loanAmount) *
            this.mainModel.interestRateValue) /
          100;
        const payAmount =
          parseInt(unMaskPrice(this.mainModel.loanAmount)) + interestAmount;
        const totalAmount = Math.ceil(payAmount / tenorValue);
        return totalAmount;
      }
      return 0;
    },
    downpayment() {
      const result =
        (parseInt(unMaskPrice(this.mainModel.prepaidAmount)) /
          this.totalAmount) *
        100;
      return Math.ceil(result);
    },
    isAbleToUpload() {
      const uploadAbleStatus = [
        LOAN_APPLICATION_STATUS.DISBURSED,
        LOAN_APPLICATION_STATUS.OP_PROCESSING,
        LOAN_APPLICATION_STATUS.APPROVED,
      ];
      return uploadAbleStatus.includes(this.mainModel.ddvStatus);
    },
  },
  components: {
    datePicker,
  },
  created() {},
  methods: {
    convertPrice(value) {
      return convertPrice(value);
    },
    showModal: function () {
      this.clearData();
      this.getProvices('present');
      this.getProvices('permanent');
      this.getLoanCampaigns();
      this.$refs['loan-data-modal'].show();
      if (this.mode === 'DETAIL') {
        this.getInfo();
      } else {
        this.getDataInit();
        this.initTitle();
      }
    },
    initTitle() {
      this.titelModal = `<div>
              <span class='d-block' style='font-size: 1.1rem'>Thông tin khoản vay đơn hàng <a class='text-primary'>#${this.mainModel.partner}</a>-ID:${this.trackingId}</span>
              </div>
             `;
    },
    fetchCustomers: function (textSearch) {
      ApiService.query(`customer/get-by-phone`, {
        params: {
          phoneNumber: textSearch,
        },
      }).then((data) => {
        const customers = data.data.data;
        this.filteredCustomers = customers;
      });
    },
    searchCustomerAPI(textInput) {
      if (!textInput || !textInput.trim().length) {
        return;
      }
      this.fetchCustomers(textInput);
    },
    debounceInput: debounce((textInput, fn) => {
      fn(textInput);
    }, TIME_TRIGGER),
    onInputed(textInput = '', type) {
      switch (type) {
        case 'Customer':
          this.debounceInput(textInput, this.searchCustomerAPI);
          break;
        default:
          break;
      }
    },
    getDataInit() {
      this.mainModel.orderId = this.loanApplicationData.orderId;
      this.mainModel.phone = this.loanApplicationData.phone
        ? this.loanApplicationData.phone
        : '';
      this.mainModel.email = this.loanApplicationData.email
        ? this.loanApplicationData.email
        : '';
      this.mainModel.fullName = this.loanApplicationData.fullName
        ? this.loanApplicationData.fullName
        : '';
      this.mainModel.loanAmount = this.loanApplicationData.loanAmount
        ? this.loanApplicationData.loanAmount
        : 0;
      this.mainModel.prepaidAmount = this.loanApplicationData.prepaidAmount
        ? this.loanApplicationData.prepaidAmount
        : 0;
      this.mainModel.partner = 'SHINHAN';
    },
    getInfo() {
      if (!this.loanApplicationId) {
        return;
      }
      ApiService.get(`loan-application/${this.loanApplicationId}`)
        .then(({ data }) => {
          this.originalStatus = data.data.ddvStatus
            ? parseInt(data.data.ddvStatus)
            : 1;
          this.mainModel.ddvStatus = data.data.ddvStatus
            ? parseInt(data.data.ddvStatus)
            : 1;
          this.mainModel.partner = data.data.partner ? data.data.partner : '';
          this.mainModel.phone = data.data.phone ? data.data.phone : '';
          this.mainModel.email = data.data.email ? data.data.email : '';
          this.mainModel.fullName = data.data.fullName
            ? data.data.fullName
            : '';
          this.mainModel.loanAmount = data.data.loanAmount
            ? data.data.loanAmount
            : 0;
          this.mainModel.prepaidAmount = data.data.prepaidAmount
            ? data.data.prepaidAmount
            : 0;
          this.mainModel.interestRateValue = data.data.interestRateValue
            ? parseInt(data.data.interestRateValue)
            : 0;
          this.mainModel.gender = data.data.gender ? data.data.gender : 1;
          this.mainModel.dateOfBirth = data.data.dateOfBirth
            ? moment(data.data.dateOfBirth).format('DD-MM-YYYY')
            : '';
          this.mainModel.idCard = data.data.idCard ? data.data.idCard : '';
          this.mainModel.idCardType = data.data.idCardType
            ? data.data.idCardType
            : 1;
          this.mainModel.presentHouseNo = data.data.presentHouseNo
            ? data.data.presentHouseNo
            : '';
          this.mainModel.presentStreet = data.data.presentStreet
            ? data.data.presentStreet
            : '';
          this.mainModel.permanentHouseNo = data.data.permanentHouseNo
            ? data.data.permanentHouseNo
            : '';
          this.mainModel.permanentStreet = data.data.permanentStreet
            ? data.data.permanentStreet
            : '';
          this.mainModel.promotionCode = data.data.promotionCode
            ? data.data.promotionCode
            : '';
          this.mainModel.tenorCode = data.data.tenorCode
            ? data.data.tenorCode
            : '';
          this.mainModel.emi = data.data.emi ? data.data.emi : 0;
          //address
          this.mainModel.permanentCityCode = data.data.permanentCityCode
            ? data.data.permanentCityCode
            : '';
          this.mainModel.permanentDistrictCode = data.data.permanentDistrictCode
            ? data.data.permanentDistrictCode
            : '';
          this.mainModel.permanentWardCode = data.data.permanentWardCode
            ? data.data.permanentWardCode
            : '';
          this.mainModel.presentCityCode = data.data.presentCityCode
            ? data.data.presentCityCode
            : '';
          this.mainModel.presentDistrictCode = data.data.presentDistrictCode
            ? data.data.presentDistrictCode
            : '';
          this.mainModel.presentWardCode = data.data.presentWardCode
            ? data.data.presentWardCode
            : '';
          this.fileInvoiceUrl = data.data.invoiceImageUrl
            ? data.data.invoiceImageUrl
            : '';
          this.fileLoanConfirmUrl = data.data.loanConfirmationImageUrl
            ? data.data.loanConfirmationImageUrl
            : '';
          this.filePrepaidReceiptsUrl = data.data.receiptImageUrl
            ? data.data.receiptImageUrl
            : '';
          this.trackingId = data.data.trackingId ? data.data.trackingId : '';
          this.getDistricts('permanent');
          this.getDistricts('present');
          this.getWards('permanent');
          this.getWards('present');
          this.initTitle();
          this.checkValidateDownpayment();
          this.checkValidateLoanAmount();
        })
        .catch(({ response }) => {
          makeToastFaile(response.$error);
        });
    },
    onSave() {
      if (!this.validateLoanAmount || !this.validateDownpayment) {
        makeToastFaile('Vui lòng nhập dữ liệu hợp lệ!');
        return;
      }
      const payload = cloneDeep(this.mainModel);
      payload.downpayment = this.downpayment;
      payload.loanAmount = parseInt(unMaskPrice(this.mainModel.loanAmount));
      payload.prepaidAmount = parseInt(
        unMaskPrice(this.mainModel.prepaidAmount)
      );
      payload.emi = this.emiAmount;
      payload.dateOfBirth = this.mainModel.dateOfBirth
        ? moment(this.mainModel.dateOfBirth, 'DD/MM/YYYY').format('yyyy-MM-DD')
        : null;
      const values = valuesIn(payload);
      if (
        includes(values, null) ||
        includes(values, '') ||
        includes(values, 0)
      ) {
        makeToastFaile('Vui lòng nhập đầy đủ thông tin yêu cầu!');
        return;
      }

      if (this.isNew === true) {
        this.isNew = false;
        if (this.mode === 'DETAIL') {
          this.onUpdate(payload);
        } else {
          this.onCreate(payload);
        }
      }
    },
    onUpdate(payload) {
      ApiService.put(`loan-application/${this.loanApplicationId}`, payload)
        .then(({ data }) => {
          if (data.status === 1) {
            this.isNew = true;
            makeToastSuccess(data.message);
            this.hideModal();
            setTimeout(() => {
              this.isNew = true;
            }, TIME_TRIGGER);
          } else {
            this.isNew = true;
            makeToastFaile(data.message);
          }
        })
        .catch((response) => {
          this.isNew = true;
          makeToastFaile(response.message);
        });
    },
    onCreate(payload) {
      payload.installMoneyAccountId = SHINHAN_ID;
      ApiService.post(`loan-application`, payload)
        .then(({ data }) => {
          if (data.status === 1) {
            this.isNew = true;
            makeToastSuccess(data.message);
            this.hideModal();
            setTimeout(() => {
              this.isNew = true;
              this.$emit('onCreateSuccess', data.data);
            }, TIME_TRIGGER);
          } else {
            this.isNew = true;
            makeToastFaile(data.message);
          }
        })
        .catch((response) => {
          this.isNew = true;
          makeToastFaile(response.message);
        });
    },
    hideModal: function () {
      this.$refs['loan-data-modal'].hide();
    },
    getProvices(mode) {
      ApiService.get(`svfc-master-data/provinces`)
        .then(({ data }) => {
          const listData = data.data.map((item) => {
            return {
              id: item.cityCode,
              name: item.cityName,
            };
          });
          if (mode === 'permanent') {
            this.listCity = cloneDeep(listData);
          } else {
            this.listPresentCity = cloneDeep(listData);
          }
        })
        .catch(({ response }) => {
          makeToastFaile(response.$error);
        });
    },
    getDistricts(mode) {
      const cityCode =
        mode === 'permanent'
          ? this.mainModel.permanentCityCode
          : this.mainModel.presentCityCode;
      ApiService.get(`svfc-master-data/districts?cityCode=${cityCode}`)
        .then(({ data }) => {
          const listData = data.data.map((item) => {
            return {
              id: item.districtCode,
              name: item.districtName,
            };
          });
          if (mode === 'permanent') {
            this.listDistrict = cloneDeep(listData);
          } else {
            this.listPresentDistrict = cloneDeep(listData);
          }
        })
        .catch(({ response }) => {
          makeToastFaile(response.$error);
        });
    },
    getWards(mode) {
      const districtCode =
        mode === 'permanent'
          ? this.mainModel.permanentDistrictCode
          : this.mainModel.presentDistrictCode;
      ApiService.get(`svfc-master-data/wards?districtCode=${districtCode}`)
        .then(({ data }) => {
          const listData = data.data.map((item) => {
            return {
              id: item.wardCode,
              name: item.wardName,
            };
          });

          if (mode === 'permanent') {
            this.listWard = cloneDeep(listData);
          } else {
            this.listPresentWard = cloneDeep(listData);
          }
        })
        .catch(({ response }) => {
          makeToastFaile(response.$error);
        });
    },
    async selectFile(event, refName) {
      const files = event.target.files[0];
      this.setFile(refName, files);
      if (!files) {
        return;
      } else if (files.size > 10 * 1024 * 1024) {
        this.setFile(refName, null);
        this.clearFiles(refName);
        return makeToastFaile('Chỉ nhận file nhỏ hơn 20MB');
      }
      await this.uploadFile(refName);
    },
    clearFiles(refName) {
      this.$refs[refName].reset();
    },
    setFile(refName, value) {
      switch (refName) {
        case 'file-input-invoice':
          this.fileInvoice = value;
          break;
        case 'file-input-loan-confirm':
          this.fileLoanConfirm = value;
          break;
        case 'file-input-prepaid-receipts':
          this.filePrepaidReceipts = value;
          break;
      }
    },
    getFile(refName) {
      switch (refName) {
        case 'file-input-invoice':
          return this.fileInvoice;
        case 'file-input-loan-confirm':
          return this.fileLoanConfirm;
        case 'file-input-prepaid-receipts':
          return this.filePrepaidReceipts;
        default:
          return null;
      }
    },
    getFileType(refName) {
      switch (refName) {
        case 'file-input-invoice':
          return 511;
        case 'file-input-loan-confirm':
          return 512;
        case 'file-input-prepaid-receipts':
          return 513;
        default:
          return null;
      }
    },
    async uploadFile(refName) {
      const file = this.getFile(refName);
      const fileType = this.getFileType(refName);
      if (!file || !fileType) {
        makeToastFaile('File không hợp lệ!');
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.onSendFile(reader.result, fileType);
      };
    },
    getLoanCampaigns() {
      ApiService.get(`svfc-master-data/programs`)
        .then(({ data }) => {
          this.listCampaign = data.data;
        })
        .catch(({ response }) => {
          makeToastFaile(response.$error);
        });
    },
    onSelectedCampaign() {
      const campaign = this.listCampaign.find(
        (x) => x.campaignCode === this.mainModel.promotionCode
      );
      this.mainModel.interestRateValue = campaign ? campaign.interest : 0;
      this.listTenor = LOAN_TENOR.filter(
        (x) => x.value >= campaign.tenorFrom && x.value <= campaign.tenorTo
      );
      this.checkValidateDownpayment();
      this.checkValidateLoanAmount();
    },
    checkValidateDownpayment() {
      const campaign = this.listCampaign.find(
        (x) => x.campaignCode === this.mainModel.promotionCode
      );
      if (!campaign) {
        this.validateDownpayment = true;
        this.messageErrorDownpayment = '';
        return;
      }

      if (
        campaign.minDownpayment <= this.downpayment &&
        this.downpayment <= campaign.maxDownpayment
      ) {
        this.validateDownpayment = true;
        this.messageErrorDownpayment = '';
        return;
      }

      const minDown = (this.totalAmount * campaign.minDownpayment) / 100;
      const maxDown = (this.totalAmount * campaign.maxDownpayment) / 100;
      this.messageErrorDownpayment = `Số tiền trả trước từ ${this.convertPrice(
        minDown
      )} đến ${this.convertPrice(maxDown)}`;
      this.validateDownpayment = false;
    },
    checkValidateLoanAmount() {
      const campaign = this.listCampaign.find(
        (x) => x.campaignCode === this.mainModel.promotionCode
      );
      const loanAmount = parseInt(unMaskPrice(this.mainModel.loanAmount));
      if (!campaign) {
        this.validateLoanAmount = true;
        this.messageErrorLoanAmount = '';
        return;
      }

      if (
        campaign.minLoanAmount <= loanAmount &&
        loanAmount <= campaign.maxLoanAmount
      ) {
        this.validateLoanAmount = true;
        this.messageErrorLoanAmount = '';
        return;
      }

      this.messageErrorLoanAmount = `Số tiền vay từ ${this.convertPrice(
        campaign.minLoanAmount
      )} đến ${this.convertPrice(campaign.maxLoanAmount)}`;
      this.validateLoanAmount = false;
    },
    onSubmit() {
      if (!this.validateLoanAmount || !this.validateDownpayment) {
        makeToastFaile('Vui lòng nhập dữ liệu hợp lệ!');
        return;
      }

      const payload = cloneDeep(this.mainModel);
      payload.downpayment = this.downpayment;
      payload.loanAmount = parseInt(unMaskPrice(this.mainModel.loanAmount));
      payload.prepaidAmount = parseInt(
        unMaskPrice(this.mainModel.prepaidAmount)
      );
      payload.emi = this.emiAmount;
      payload.dateOfBirth = this.mainModel.dateOfBirth
        ? moment(this.mainModel.dateOfBirth, 'DD/MM/YYYY').format('yyyy-MM-DD')
        : null;
      if (this.isNew === true) {
        this.isNew = false;
        ApiService.put(`loan-application/${this.loanApplicationId}`, payload)
          .then(({ data }) => {
            if (data.status === 1) {
              this.onSendApplication();
            } else {
              this.isNew = true;
              makeToastFaile(data.message);
            }
          })
          .catch((response) => {
            this.isNew = true;
            makeToastFaile(response.message);
          });
      }
    },
    onSendApplication() {
      ApiService.put(`loan-application/${this.loanApplicationId}/submit`)
        .then(({ data }) => {
          if (data.status === 1) {
            this.isNew = true;
            makeToastSuccess(data.message);
            this.hideModal();
            setTimeout(() => {
              this.isNew = true;
            }, TIME_TRIGGER);
          } else {
            this.isNew = true;
            makeToastFaile(data.message);
          }
        })
        .catch((response) => {
          this.isNew = true;
          makeToastFaile(response.message);
        });
    },
    onSendFile(fileBase64, fileType) {
      const payload = {
        fileBase64,
        fileType,
      };
      ApiService.put(
        `loan-application/${this.loanApplicationId}/uploadFileImage`,
        payload
      )
        .then(({ data }) => {
          if (data.status === 1) {
            makeToastSuccess(data.message);
          } else {
            this.isNew = true;
            makeToastFaile(data.message);
          }
        })
        .catch((response) => {
          this.isNew = true;
          makeToastFaile(response.message);
        });
    },
    onChangeStatus() {
      if (
        this.mainModel.ddvStatus !== this.originalStatus &&
        this.mainModel.ddvStatus === 1
      ) {
        this.showConfirmBox();
      }
    },
    showConfirmBox: function () {
      const loanStatus = this.LOAN_STATUS.find(
        (x) => x.value === this.originalStatus
      );
      const loanStatusName = loanStatus ? loanStatus.text : '';
      Swal.fire({
        title: 'Xác nhận chuyển trạng thái!',
        text: `Hồ sơ đang ở trạng thái: ${loanStatusName}, bạn có chắc muốn chuyển thành trạng thái mới không?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3699FF',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Đồng ý',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
        position: 'top',
      }).then((result) => {
        if (result.dismiss === Swal.DismissReason.cancel) {
          this.mainModel.ddvStatus = this.originalStatus;
        }
      });
    },
    clearData() {
      this.mainModel = {
        phone: '',
        fullName: '',
        presentHouseNo: '',
        presentStreet: '',
        permanentHouseNo: '',
        permanentStreet: '',
        permanentCityCode: '',
        presentCityCode: '',
        permanentDistrictCode: '',
        permanentWardCode: '',
        presentDistrictCode: '',
        presentWardCode: '',
        gender: 1,
        email: '',
        dateOfBirth: '',
        promotionCode: '',
        prepaidAmount: 0,
        loanAmount: 0,
        tenorCode: '',
        emi: 0,
        interestRateValue: 0,
        idCard: '',
        idCardType: 1,
        ddvStatus: 1,
        partner: '',
      };
    },
    checkValidIdCard() {
      if (this.mainModel.idCardType === 0) {
        if (
          this.mainModel.idCard.length !== 9 &&
          this.mainModel.idCard.length !== 12
        ) {
          this.validateIdCard = false;
          this.messageErrorIdCard = 'Vui lòng nhập mã CMND có 9 hoặc 12 ký tự';
          return;
        }
        this.validateIdCard = true;
        this.messageErrorIdCard = '';
        return;
      }

      if (
        this.mainModel.idCardType === 1 &&
        this.mainModel.idCard.length !== 12
      ) {
        this.validateIdCard = false;
        this.messageErrorIdCard = 'Vui lòng nhập mã CCCD có 12 ký tự';
        return;
      }

      this.validateIdCard = true;
      this.messageErrorIdCard = '';
    },
  },
};
</script>

<style>
.autosuggest__results {
  position: absolute;
  width: 100%;
  max-height: 200px;
  z-index: 999;
  border: 1px solid #e0e0e0;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #fff;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

.autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.35em + 1.1rem + 2px);
  line-height: 1.35;
  padding: 0.55rem 0.75rem;
  font-weight: 400;
  font-size: 0.925rem;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.28rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}
.autosuggest {
  width: 100%;
}
.select-style {
  border-radius: 0.28rem;
  box-shadow: none;
  border: 1px solid #ced4da;
}
</style>